import React, { useState, useEffect } from 'react';

import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import SitesBox from './site-box/SitesPage';
import { cssColorBlack, cssTextDecorationNone } from '../core/cssStuff';
import { i18nKeys } from '../i18n/i18nKeys';

interface Props {
  i18n: i18nKeys;
}

const SitesPage = ({ i18n }: Props) => {

  const [sites, setSites] = useState<null | Array<{ site_id: string, name?: string }>>(null);

  useEffect(() => {
    fetch(`/api/sites`)
      .then(response => response.json())
      .then(data => {
        setSites(data.sites);
      }).catch(error => {
        // Handle any errors that occurred during the fetch request
        console.error('Error:', error);
      });
  }, []);

  return (
    <section>
      » {i18n.sites_page_sites} <sup><Link to="/sites/new" className={cssTextDecorationNone}>+</Link></sup>
      <h2>{i18n.sites_page_your_sites}</h2>
      <div className="flex-box-container-2-columns">
      
        {sites && (sites.length === 0) &&
          <div>
            <div>
              {i18n.sites_nosite_cannot_find_site}
            </div>
            <div>
              <Link to="/sites/new">{i18n.sites_nosite_create_first_site}</Link>
            </div>
          </div>
        }

        {sites && (sites.length > 0) && sites.map(site => {
          return <SitesBox siteId={site.site_id} siteName={site.name}></SitesBox>
        })}

      </div>
    </section>

  );
};

export default SitesPage;