import React, { useEffect, useRef, useState } from 'react';
import { cssCodeSnippet, cssNoDataBox } from '../../core/cssStuff';


interface IProps {
  siteId: string
}

const NoDataBannerWithScript = (props: IProps) => {

  const [hasHits, setHasHits] = useState(true);
  const codeBlockRef = useRef<HTMLElement>(null);
  
  useEffect(() => {
    checkIfSiteHasData();
  }, []);

  const checkIfSiteHasData = async () => {
    const requestOptions: RequestInit = {
      method: 'GET',
    };
    const response = await fetch(`/api/sites/${props.siteId}/has-hits`, requestOptions);
    const hasHitsResponse = await response.json() as HasHitsResponse;

    setHasHits(hasHitsResponse.has_hits);
  }

  const copyCodeSnippetToClipboard = async () => {
    try {
      if (codeBlockRef && codeBlockRef.current && codeBlockRef.current.textContent) {
        await navigator.clipboard.writeText(codeBlockRef.current.textContent);
        console.log('Copied to clipboard!');
      }

    } catch (e) {
      console.log('Error. Failed to copy text!' + e);
    }
  };

  if (hasHits) {
    return <></>;
  } else {

    const snippet: string = `<script defer src=https://app.privatracker.com/visit.js data-site-id=${props.siteId}></script>`

    return <div className={cssNoDataBox}>
      <h2>No data on this site - yet</h2>
      <div>
        <p>Copy and paste the following into the webpage you want to analyze:</p>
        <code className={cssCodeSnippet} ref={codeBlockRef}>
          {snippet}
        </code>
        <button onClick={copyCodeSnippetToClipboard}>Copy to clipboard</button>
        <p>Got any questions? Don't hesitate to reach out to us - we are always here to help!</p>
      </div>
    </div>;
  }
}

interface HasHitsResponse {
  has_hits: boolean;
}

export default NoDataBannerWithScript;
