import React, { useEffect, useState } from 'react';

import { cssFlexBox, cssTextAlignLeft, cssTextAlignRight } from '../../core/cssStuff';
import { createQueryParametersForBackendApiCall } from '../../core/BackendQueryMaker';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { i18nKeys } from '../../i18n/i18nKeys';


interface IProps {
  siteId: string,
  i18n: i18nKeys
}


const TopSources = ({siteId, i18n}: IProps) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [topReferrer, setTopReferrer] = useState<Array<{ referrer_hostname: string, count: string }>>([]);

  let location = useLocation();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    updateTable();
  }, [searchParams]);


  const updateTable = () => {
    let url = createQueryParametersForBackendApiCall(`/api/sites/${siteId}/top-referrer`, searchParams);

    fetch(url)
      .then(response => response.json())
      .then(data => {

        setLoading(false);
        setTopReferrer(data);

      }).catch(error => {
        // Handle any errors that occurred during the fetch request
        console.error('Error:', error);
      });
  }

  const createLinkForFilter = (source: string): string => {
    const newSearchParams = new URLSearchParams();
    searchParams.forEach((value, key) => {
      newSearchParams.append(key, value);
    });

    newSearchParams.set('source', source);
    return location.pathname + '?' + newSearchParams.toString();
  }

  if (loading) {
    return (
      <div className={cssFlexBox}>
        <h2>{i18n.analytics_page_top_sources}</h2>
        <p>{i18n.loading}</p>
      </div>
    );
  }

  const rows = topReferrer.map((item, index) => (
    <tr key={index}>
      <td className="word-break-break-all cursor-pointer"><Link to={createLinkForFilter(item.referrer_hostname)}>{item.referrer_hostname}</Link></td>
      <td className={cssTextAlignRight}>{item.count}</td>
    </tr>
  ));

  return (
    <div className={cssFlexBox}>
      <h2 className="uppercase">{i18n.analytics_page_top_sources}</h2>
      <table className="data-table">
        <thead>
          <tr>
            <th className={`${cssTextAlignLeft} uppercase`}>{i18n.analytics_page_source}</th>
            <th className={`${cssTextAlignRight} uppercase`}>{i18n.views}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  );

}

export default TopSources;             
