import { useState } from "react";

const AcceptPrivacyPolicyOverlay = ({ onClose }: any) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px'
      }}>
        <h2>Howdy!</h2>

        <p>Um unsere Software zu verwenden ist es erforderlich unseren <a href="https://www.privatracker.com/assets/legal/2024-01-31-privatracker-cloud-datenverarbeitungsvertrag.pdf" target="_blank">Datenverarbeitungsvertrag</a> zu akzeptieren.</p>

        <p>
          <div>
            <input type="checkbox" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
            <label>Ja, ich habe den Datenverarbeitungsvertrag gelesen und stimme zu.</label>
          </div>
        </p>
        <br/>
        <br/>

        <button disabled={!isChecked} onClick={onClose}>Ok</button>
      </div>
    </div>
  );
};

export default AcceptPrivacyPolicyOverlay;