import { ReactElement } from 'react';

import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { cssFiltersHeader, cssFiltersTag } from '../../core/cssStuff';

const FiltersHeader = () => {

  let [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();

  const getLinkWithoutKey = (keyToRemove: string) => {
    const newSearchParams = new URLSearchParams();
    searchParams.forEach((value, key) => {
      if (key !== keyToRemove) {
        newSearchParams.append(key, value);
      }
    })
    return location.pathname + '?' + newSearchParams.toString();
  }

  const renderParams = () => {
    let renderedParams: Array<ReactElement> = [];
    searchParams.forEach((value, key) => {
      renderedParams.push(<span className={cssFiltersTag}>{key} {value} <Link to={getLinkWithoutKey(key)} className='text-decoration-none'>&times;</Link></span>);
    });
    return renderedParams;
  }

  return (
    <div className={cssFiltersHeader}>{renderParams()}</div>
  );

}

export default FiltersHeader;             
