import React, { useState, FormEvent } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { cssFlexBoxContainerRow2Columns, cssTextDecorationNone } from '../core/cssStuff';
import { i18nKeys } from '../i18n/i18nKeys';

interface Props {
    i18n: i18nKeys;
  }

const SitesPageNew = ({i18n} : Props) => {

    //let history = useHistory();
    const [name, setName] = useState<string>(""); //the state is strongly typed with <string>
    const navigate = useNavigate();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => { //type defined for event
        setName(event.target.value);
    };

    const handleSubmit = async (event: FormEvent) => { //type defined for event
        event.preventDefault();

        //request options
        const requestOptions: RequestInit = { //type defined for requestOptions
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name })
        };

        // send a POST request
        const response = await fetch('/api/sites', requestOptions);

        if(response.ok) { //check if response is ok
            navigate("/sites");
        }
    };

    return ( 
        <section>
        » <Link to="/sites" className={cssTextDecorationNone}>{i18n.sites_page_new_sites}</Link> » {i18n.sites_page_new_new}
        <h2>{i18n.sites_page_new_create_new_site}</h2>
        <div className={cssFlexBoxContainerRow2Columns}>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={name}
                    placeholder={i18n.sites_page_new_url_example}
                    onChange={handleChange}
                />
                <button type="submit">{i18n.sites_page_new_create}</button>
            </form>
            </div>
            </section>
    );
}

export default SitesPageNew;