export const highlightColor = "#00be00";
export const cssTextDecorationNone = "text-decoration-none";
export const cssColorBlack = "color-black";

export const cssFlexBoxContainerRow2Columns = "flex-box-container-2-columns";
export const cssFlexBox = "flex-box";

export const cssTextAlignLeft = "text-align-left";
export const cssTextAlignRight = "text-align-right";
export const cssNoDataBox = "no-data-box";
export const cssCodeSnippet = "code-snippet";


export const cssFiltersHeader = "filters-header";
export const cssFiltersTag = "filters-tag";
