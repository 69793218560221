import { de_DE } from "./de_DE";
import { i18nKeys } from "./i18nKeys";

export class Translations {
    translations: i18nKeys;
    private userLocale: string;

    constructor() {
        this.userLocale = 'de-DE'; // = navigator.language;

        // switch(this.userLocale) {
        //     case 'de-DE':
        //       this.translations = de_DE;
        //       break;
        //     default:
        //         this.translations = en_US;
        //   }

        // Only German for now
        this.translations = de_DE;
    }
    
    formattedDate(date: Date): string {
        return date.toLocaleDateString(this.userLocale);
    }

}

export function formatDate(date: Date, format: string): string {
    let day = date.getDate();
    let month = date.getMonth() + 1; // Months are 0-based in JavaScript
    let year = date.getFullYear();
  
    // Pad the day and month with a 0 if they are single digit
    // if (day < 10) day = '0' + day;
    // if (month < 10) month = '0' + month;
  
    // Replace format tokens with date values
    format = format.replace('DD', day.toString());
    format = format.replace('MM', month.toString());
    format = format.replace('YYYY', year.toString());
  
    return format;
  }

