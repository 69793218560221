import React, { useEffect, useState } from 'react';

import './TopBrowser.css';
import { cssFlexBox, cssTextAlignLeft, cssTextAlignRight } from '../../core/cssStuff';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { createQueryParametersForBackendApiCall } from '../../core/BackendQueryMaker';
import { i18nKeys } from '../../i18n/i18nKeys';

interface IProps {
  siteId: string,
  i18n: i18nKeys
}

const TopBrowser = ({siteId, i18n}: IProps) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Array<{browser: string, count: string}>>([]);

  let location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    updateTable();
  }, [searchParams]);

  const updateTable = () => {
    let url = createQueryParametersForBackendApiCall(`/api/sites/${siteId}/browser-and-count`, searchParams);

    fetch(url)
      .then(response => response.json())
      .then(json => {

        setLoading(false);
        setData(json.data);

      }).catch(error => {
        // Handle any errors that occurred during the fetch request
        console.error(i18n.error + ":", error);
      });
  }

  const createLinkForFilter = (browser: string): string => {
    const newSearchParams = new URLSearchParams();
    searchParams.forEach((value, key) => {
      newSearchParams.append(key, value);
    });
    newSearchParams.set('browser', browser);

    return location.pathname + '?' + newSearchParams.toString();
  }

    if (loading) {
      return (<div className={cssFlexBox}>
        <h2>{i18n.analytics_page_top_browser}</h2>
        <p>{i18n.loading}</p>
      </div>
      );
    }

    const rows = data.map((item, index) => (
      <tr key={index}>
        <td className="word-break-break-all cursor-pointer"><Link to={createLinkForFilter(item.browser)}>{item.browser}</Link></td>
        <td className={cssTextAlignRight}>{item.count}</td>
      </tr>
    ));

    return (
      <div className={cssFlexBox}>
        <h2 className="uppercase">{i18n.analytics_page_top_browser}</h2>
        <table className="data-table">
          <thead>
            <tr>
              <th className={`${cssTextAlignLeft} uppercase`}>{i18n.analytics_page_browser}</th>
              <th className={`${cssTextAlignRight} uppercase`}>{i18n.views}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table></div>
    );

}

export default TopBrowser;             
