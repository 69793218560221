import React, { useState, useEffect } from 'react';

import './AnalyticsPage.css';
import NumbersHeader from './numbers-header/NumbersHeader';
import MainChart from './main-chart/MainChart';
import TopPages from './top-pages/TopPages';
import TopSources from './top-sources/TopSources';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import TopCountries from './top-countries/TopCountries';
import TopBrowser from './top-browser/TopBrowser';
import { cssColorBlack, cssFlexBoxContainerRow2Columns, cssTextDecorationNone } from '../core/cssStuff';
import NoDataBannerWithScript from './no-data-banner-with-script/NoDataBannerWithScript';
import FiltersHeader from './filter-header/FiltersHeader';
import { i18nKeys } from '../i18n/i18nKeys';


interface Props {
  i18n: i18nKeys;
}

const AnalyticsPage = ({i18n}: Props) => {

  const { siteName } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [siteId, setSiteId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {

    fetch(`/api/sites`)
      .then(response => response.json())
      .then(data => {
        const res = data.sites.find((d: { site_id: string, name: string }) => d.name === siteName);

        if (res) {
          setSiteId(res.site_id);
        } else {
          setError(i18n.analytics_page_cant_find_site + " " + siteName);
        }

      }).catch(error => {
        // Handle any errors that occurred during the fetch request
        console.error(i18n.error + ":", error);
      });

  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    searchParams.set('range', event.target.value);
    setSearchParams(searchParams)
  }

  const dropDownValue = (): string => {

    if (typeof searchParams.get('range') === 'string') {
      return searchParams.get('range') as string;
    } else {
      return 'month';
    }

  }

  if (error) {
    return <div>{error}</div>;
  } else if (!siteId) {
    return <div>{i18n.loading}</div>;
  }

  return (
    <section>
      » <Link to="/sites" className={cssTextDecorationNone + " " + cssColorBlack}>{i18n.sites}</Link> » {siteName}
    
      <NoDataBannerWithScript siteId={siteId}></NoDataBannerWithScript>

      <h2>{siteName}</h2>

      <FiltersHeader></FiltersHeader>

      <div>
        <select value={dropDownValue()} onChange={handleChange}>
          <option value="today">{i18n.analytics_page_date_selection_today}</option>
          <option value="yesterday">{i18n.analytics_page_date_selection_yesterday}</option>
          <option value="week">{i18n.analytics_page_date_selection_week}</option>
          <option value="month">{i18n.analytics_page_date_selection_month}</option>
          <option value="year">{i18n.analytics_page_date_selection_year}</option>
          <option value="all">{i18n.analytics_page_date_selection_all_time}</option>
        </select>
      </div>

      <NumbersHeader siteId={siteId} i18n={i18n}></NumbersHeader>
      <MainChart siteId={siteId} i18n={i18n}></MainChart>
      <div className={cssFlexBoxContainerRow2Columns}>
        <TopPages siteId={siteId} i18n={i18n}></TopPages>
        <TopSources siteId={siteId} i18n={i18n}></TopSources>
      </div>
      <div className={cssFlexBoxContainerRow2Columns}>
        <TopCountries siteId={siteId} i18n={i18n}></TopCountries>
        <TopBrowser siteId={siteId} i18n={i18n}></TopBrowser>
      </div>
    </section>
  );
};

export default AnalyticsPage;