import React, { useEffect, useState } from 'react';

import './NumbersHeader.css';
import { useSearchParams } from 'react-router-dom';
import { createQueryParametersForBackendApiCall } from '../../core/BackendQueryMaker';
import { i18nKeys } from '../../i18n/i18nKeys';

interface IProps {
  siteId: string,
  i18n: i18nKeys
}

const NumbersHeader = ({siteId, i18n}: IProps) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [pageViewsCount, setPageViewsCount] = useState<number>(0);
  const [visitorCount, setVisitorCount] = useState<number>(0);
  const [currentNumberOfUsersOnSite, setCurrentNumberOfUsersOnSite] = useState<number>(0);

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    updateNumbers();
  }, [searchParams]);

  const updateNumbers = async () => {
    // currently on site - live
    const currentNumberOfUsersResponse = await fetch(`/api/sites/${siteId}/current-number-of-users`)
    const currentNumberOfUsersData = await currentNumberOfUsersResponse.json();
    const currentNumberOfUsersOnSite = currentNumberOfUsersData.count;

    // pageviews
    let pageViewsCountUrl = createQueryParametersForBackendApiCall(`/api/sites/${siteId}/page-view-count`, searchParams);
    const pageViewsCountResponse = await fetch(pageViewsCountUrl)
    const pageViewsCountData = await pageViewsCountResponse.json();
    const pageViewsCount = pageViewsCountData.page_view_count;

    // visitor count
    let visitorCountUrl = createQueryParametersForBackendApiCall(`/api/sites/${siteId}/visitor-count`, searchParams);
    const visitorCountResponse = await fetch(visitorCountUrl);
    const visitorCountData = await visitorCountResponse.json();
    const visitorCount = visitorCountData.visitor_count;

    setLoading(false);
    setPageViewsCount(pageViewsCount);
    setCurrentNumberOfUsersOnSite(currentNumberOfUsersOnSite);
    setVisitorCount(visitorCount);

  }


  if (loading) {
    return (<div>
      <p>{i18n.loading}</p>
    </div>);
  }


  return (
    <div className="numbersHeader">
      <div>
        <h3 className="numbersHeaderHeading">{i18n.analytics_page_numbers_header_now_on_site}</h3>
        <div className="numbersHeaderNumber">{currentNumberOfUsersOnSite}</div>
      </div>
      <div><h3 className="numbersHeaderHeading">{i18n.visitor_count}</h3>
        <div className="numbersHeaderNumber">{visitorCount}</div>
      </div>
      <div><h3 className="numbersHeaderHeading">{i18n.page_views}</h3>
        <div className="numbersHeaderNumber">{pageViewsCount}</div>
      </div>
    </div>
  );


}

export default NumbersHeader;             
