import { i18nKeys } from "./i18nKeys";

export const de_DE: i18nKeys = {
  date_format: "DD.MM.YYYY",

  sites: "Sites",
  error: "Fehler",
  loading: "Lade Daten...",
  views: "Aufrufe",
  visitor_count: "Besucher",
  page_views: "Aufgerufene Seiten",

  settings_title: "Einstellungen",
  settings_legal_section_title: "Rechtliches",
  settings_contract: "Datenverarbeitungsvertrag",

  sites_nosite_cannot_find_site: "📣📣📣 Sieht so aus als würdest du noch keine Site tracken. 📣📣📣",
  sites_nosite_create_first_site: "Klicke hier um deine erste Site einzurichten!'",

  sites_page_new_new: "Neu",
  sites_page_new_sites: "Sites",
  sites_page_new_create_new_site: "Neue Site erstellen",
  sites_page_new_create: "Erstellen",
  sites_page_new_url_example: "https://www.example.com",

  sites_page_sites: "Sites",
  sites_page_your_sites: "Alle Sites",

  analytics_page_date_selection_today: "Heute",
  analytics_page_date_selection_yesterday: "Gestern",
  analytics_page_date_selection_week: "Woche",
  analytics_page_date_selection_month: "Monat",
  analytics_page_date_selection_year: "Jahr",
  analytics_page_date_selection_all_time: "Kompletter Zeitraum",
  analytics_page_cant_find_site: "Opsi. Ich kann folgende Seite nicht finden: ",


  analytics_page_top_sources: "Top Quellen",
  analytics_page_source: "Quelle",
  analytics_page_top_pages: "Top Seiten",
  analytics_page_page: "Seite",
  analytics_page_top_countries: "Top Länder",
  analytics_page_country: "Land",
  analytics_page_top_browser: "Top Browser",
  analytics_page_browser: "Browser",
  analytics_page_numbers_header_now_on_site: "Jetzt auf der Site"
}