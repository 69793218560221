import React, { useState, useEffect } from 'react';

import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { highlightColor } from '../../core/cssStuff';


interface IProps {
  siteId: string,
  siteName: string | undefined
}

const SitesBox = (props: IProps) => {

  const [chartData, setChartData] = useState<undefined | Array<{ legend: string, count: number }>>(undefined);

  useEffect(() => {
    updateChart();
  }, []);

  const updateChart = () => {
    fetch(`/api/sites/${props.siteId}/hits-per-range?range=month`)
      .then((response) => response.json())
      .then((jsonData: HitsPerRangeRoot) => {

        const data = jsonData.data.map(e => {
          var date = new Date(e.day);
          var year = date.getFullYear();
          var month = (1 + date.getMonth()).toString().padStart(2, '0'); // getMonth() is zero-indexed
          var day = date.getDate().toString().padStart(2, '0');

          const legend = year + '-' + month + '-' + day; // format date as "YYYY-MM-DD"
          const count = e.count;

          return {
            legend: legend,
            count: parseInt(count)
          }
        });

        setChartData(data);

      });
  }


  let siteLinkString;
  if (props.siteName) {
    siteLinkString = "/sites/" + props.siteName;
  } else {
    siteLinkString = "/sites/" + props.siteId;
  }

  const chart =
    <ResponsiveContainer width="100%" height="100%" minWidth="350px" minHeight="200px">
      <LineChart
        width={350}
        height={200}
        data={chartData}
        className="cursor-pointer-important"
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Line type="monotone" dataKey="count" stroke={highlightColor} activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>


  //const siteLink = <div><Link to={siteLinkString} className="text-decoration-none color-black">{props.siteName}</Link></div>;

  return <div className="flex-box site-box">
    <Link to={siteLinkString} className="text-decoration-none">
      <div>{chart}</div>
      <div>{props.siteName}</div>
    </Link>
  </div>;
};

interface HitsPerRangeRoot {
  data: Array<HitsPerRange>
}
interface HitsPerRange {
  day: string;
  count: string;
}

export default SitesBox;