import React, { useEffect, useState } from 'react';

import './MainChart.css';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { highlightColor } from '../../core/cssStuff';
import { useSearchParams } from 'react-router-dom';
import { createQueryParametersForBackendApiCall } from '../../core/BackendQueryMaker';
import { i18nKeys } from '../../i18n/i18nKeys';
import { formatDate } from '../../i18n/translation';


// Define the shape of the API response
// {"data":[{"day":"2023-08-17T22:00:00.000Z","count":"3"}]}
interface HitsPerRangeRoot {
  data: Array<HitsPerRange>
}
interface HitsPerRange {
  day: string;
  count: string;
}

// Define the shape of the state
interface State {
  chartData: {
    legend: string,
    count: number
  }[];
}



interface IProps {
  siteId: string,
  i18n: i18nKeys
}

const MainChart = (props: IProps) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<Array<{ legend: string, count: number }>>([]);

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    updateChart();
  }, [searchParams]);

  const updateChart = () => {
    let url = createQueryParametersForBackendApiCall(`/api/sites/${props.siteId}/hits-per-range`, searchParams);


    fetch(url)
      .then((response) => response.json())
      .then((jsonData: HitsPerRangeRoot) => {

        const data = jsonData.data.map(e => {
          var date = new Date(e.day);
          const dateFormatI18n = props.i18n.date_format;

          const formattedDate = formatDate(date, dateFormatI18n);


          //var year = date.getFullYear();
          //var month = (1 + date.getMonth()).toString().padStart(2, '0'); // getMonth() is zero-indexed
          //var day = date.getDate().toString().padStart(2, '0');

          //const legend = year + '-' + month + '-' + day; // format date as "YYYY-MM-DD"
          const count = e.count;

          return {
            legend: formattedDate,
            count: parseInt(count)
          }
        });

        setChartData(data);

      });
  }


  return (
    <ResponsiveContainer width="100%" height="100%" minWidth="100px" minHeight="400px">
      <LineChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="legend" />
        <YAxis domain={[0, 'dataMax']} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="count" stroke={highlightColor} activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );



}

export default MainChart;             
