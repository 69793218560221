import React, { useState, useEffect } from 'react';
import { Translations } from '../i18n/translation';
import { i18nKeys } from '../i18n/i18nKeys';


interface Props {
  i18n: i18nKeys;
}

const SettingsPage = ({i18n} :Props) => {

  return (
    <section>
      <h1>{i18n.settings_title}</h1>

      <h2>{i18n.settings_legal_section_title}</h2>
      <ul>
      <li><a href="https://www.privatracker.com/assets/legal/2024-01-31-privatracker-cloud-datenverarbeitungsvertrag.pdf" target="_blank">{i18n.settings_contract}</a></li>
      </ul>
    </section>
  );
};

export default SettingsPage;